// Layout
import Layout from '@/layouts';
// Components
import DynamicZone from '@/components/shared/dynamicZone';
import Meta from '@/utils/meta';
// Utils
import constants from '@/constants';
import { wrapper } from '@/store';
import { pageTypes } from '@/utils/constants/pageTypes';
import useDynamicZoneProps from '@/utils/hooks/use-dynamic-zone-props';
import { serverSidePropsAction } from '@/utils/page/serverSidePropsAction';
import StructuredData from '@/utils/structuredData';
import get from 'lodash/get';
// Material
import { Container } from '@mui/material';
// Defaults
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	dynamicZoneSection: {
		padding: theme.spacing(5.5),
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(3, 0),
		},
	},
}));

export default function HomePage(props) {
	const { classes } = useStyles();
	const dynamicZoneProps = useDynamicZoneProps(props);

	return (
		<>
			<Meta
				metaTags={props.meta}
				noIndex={
					constants.seo.noIndexPages.has(pageTypes.page) || props.noIndex
				}
			/>
			<StructuredData data={props.generatedStructuredData} />
			<Container maxWidth="xl">
				<DynamicZone
					{...dynamicZoneProps}
					sectionClassName={classes.dynamicZoneSection}
				/>
			</Container>
		</>
	);
}

export const getServerSideProps = wrapper.getServerSideProps(
	(store) => async (ctx) => {
		ctx.res.setHeader(
			'Cache-Control',
			'public, s-maxage=3600, max-age=3600, stale-while-revalidate=60'
		);

		return await serverSidePropsAction(pageTypes.page, 'home-page', store);
	}
);

HomePage.getLayout = function getLayout(page, pageProps) {
	return (
		<Layout
			showBackgroundImage={pageProps.showBackgroundImage}
			pageFormId={pageProps.pageFormId}
			formButtonText={
				get(pageProps, 'formPageConfig.buttonText', '') ||
				get(pageProps, 'form.data.attributes.submitButtonText', '')
			}
			resolvedUrl={pageProps.resolvedUrl}
			hasDynamicZone={true}
		>
			{page}
		</Layout>
	);
};

HomePage.propTypes = {
	slug: PropTypes.string,
	pageId: PropTypes.number,
	DynamicZone: PropTypes.array,
	form: PropTypes.object,
	pageFormId: PropTypes.string,
	formPageConstants: PropTypes.object,
	formPageConfig: PropTypes.object,
	meta: PropTypes.object,
	generatedStructuredData: PropTypes.array,
	paymentDetail: PropTypes.object,
	showBackgroundImage: PropTypes.bool,
	noIndex: PropTypes.bool,
};

HomePage.defaultProps = {
	DynamicZone: [],
	showBackgroundImage: false,
	noIndex: false,
};
